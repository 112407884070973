/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%; max-width: 100%;
  overflow-x: hidden;
}
body { margin: 0; font-family: 'Poppins', sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-dialog-container {
  position: fixed;
  left: 0%;
  top: 0%;
  padding: 5em;
  background: #FFFFFFF0;
  z-index: 100000;
}
